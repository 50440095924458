@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@keyframes slidein {
  0% {top: 40%; opacity: 0%;}
  100% {top: 50%; opacity: 100%;}
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffbac2;
  font-family: Roboto Mono;
  overflow-x: hidden;
}

.App {
  width: 100%;
  height: 100vh;
  position: relative;
  .container {
    margin: 0 auto;
    .slide-in-top {
      opacity: 100%;
      position: absolute;
      animation: slidein 3s;
    }
    .containerBox {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      //background-color: orange;
      a {
        text-decoration: none;
        color: white;
        font-style: italic;
        user-select: none;
        :hover {
          color: black;
        }
        h1 {
          margin: 0 auto;
          font-weight: 400;
          font-size: 3rem;
        }
      }
    }
  }
}